
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {YevmiyeNumarasi, FormWrapper, NoterPicker, Dates}
})
export default class VekaletForm extends Mixins(ObjectInputMixin) {
  @Prop() siradakiVekaletNo !: number;

  @Ref('formWrapper') formWrapper!: FormWrapper;
  error !: any;
  vekaletNoKullanilmis = false;

  protected onValueChange() {
    if (!this.localValue.id)
      this.localValue.vekalet_no = this.siradakiVekaletNo;
  }

  @Watch('siradakiVekaletNo')
  onSiradakiVekaletNoChange() {
    if (!this.localValue.id) {
      this.localValue.vekalet_no = this.siradakiVekaletNo;
      this.input();
    }
  }

  onClose(){
    this.$emit("onClose");
  }

  tarihRule() {
    if (this.localValue.vekalet_tarihi) {
      if (new Date(this.localValue.vekalet_tarihi).getTime() < new Date().getTime()) {
        return true;
      } else
        return 'Vekalet tarihi, bugünün tarihiden önce olmalıdır.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  rules(): string | boolean {
    if (this.localValue.yevmiye_no) {
      if (this.localValue.yevmiye_no.length <= 25)
        return true;
      else
        return 'Maksimum 25 karakter girilebilir.';
    } else
      return true;
  }

  save() {
    if (this.formWrapper.validateForm()) {
      this.formWrapper.onFly = true;
      if (this.localValue.id) {
        this.$http.put("/api/v1/vekalet/" + this.localValue.id, this.localValue).then(() => {
          this.formWrapper.setDefaultLocalValue();
          this.$emit('onSuccess')
        }).finally(() => {
          this.formWrapper.onFly = false;
        });
      } else {
        StatusCodeHelper.dontShow422Alert = true;
        this.$http.post("/api/v1/vekalet", this.localValue).then((response: any) => {
          this.$emit('onSuccess')
        }).catch((error) => {
          if (error.response.status === 422) {
            if (error.response.data.errors?.vekalet_no) {
              this.vekaletNoKullanilmis = true;
              this.input();
            }
          }
        }).finally(() => {
          StatusCodeHelper.dontShow422Alert = false;
          this.formWrapper.onFly = false;
        });
      }
    }
  }
}
